import { z } from 'zod'
import { feedrEnvironmentSchema } from '@caterdesk/utils--feedr-environment'

export const schema = z.object({
  FEEDR_ENVIRONMENT: feedrEnvironmentSchema,
  APP_VERSION: z.string().min(1),
  DATADOG_APPLICATION_ID: z.string().optional(),
  DATADOG_CLIENT_TOKEN: z.string().optional(),
  SITE_URL: z.string(),
  GRAPHQL_URL: z.string().url(),
  API_URL: z.string().url(),
  SPLIT_AUTH_KEY: z.string(),
  ALB_URL: z.string().optional(),
  URI_FOOD_DELIVERY: z.string(),
  ABOUT_LINK: z.string(),
  URI_GROUP_MEALS: z.string(),
  URI_CLOUD_CANTEEN: z.string(),
  URI_OFFICE_MANAGER: z.string(),
  URI_HR_MANAGER: z.string(),
  URI_EMPLOYEES: z.string(),
  URI_CANTEEN: z.literal('/canteen'),
  GM_MICRO_FRONTEND_URL: z.string(),
  URI_HOME_DELIVERY: z.string(),
  URI_LOCATION_LONDON: z.string(),
  URI_LOCATION_MANCHESTER: z.string(),
  URI_LOCATION_DUBLIN: z.string(),
  URI_LOCATION_EDINBURGH: z.string(),
  URI_FOOD_DELIVERY_SUPPLIERS: z.string(),
  URI_FOOD_DELIVERY_PARTNERS: z.string(),
  URI_FOOD_DELIVERY_RECOMMENDED_PACKAGES: z.string(),
  COMMIT_SHA: z.string(),
  CLOUDFLARE_OPTIMISE_IMAGE: z.boolean(),
  ENABLE_PWA: z.boolean(),
  SHOULD_FORCE_LOCALE: z.boolean(),
  AWS_PROFILE: z.string().optional(),
  MAPBOX_API_KEY: z.string(),
  TZ: z.string(),
  MIXPANEL_TOKEN: z.string(),
})

export type AppVariables = z.infer<typeof schema>
