'use client'

import React, { useEffect, useRef } from 'react'
import { MixpanelAnalytics } from '@/helpers/mixpanelConfig'
import useTenant from '@/hooks/useTenant'
import { useGetMeLazyQuery } from '@/generated/graphql'

const Providers: React.FC = () => {
  const tenant = useTenant()
  const [getMe] = useGetMeLazyQuery()
  const isFirstLoaded = useRef(true)

  useEffect(() => {
    try {
      if (typeof window !== 'undefined' && isFirstLoaded.current) {
        isFirstLoaded.current = false
        MixpanelAnalytics.init({ tenant })
        getMe().then((result) => {
          MixpanelAnalytics.login(result.data?.me)
        })
      }
    } catch (error) {
      console.error('Mixpanel error', error)
    }
  }, [])

  return null
}

export default Providers
