import mixpanel from 'mixpanel-browser'
import config from '@/config'
import { GetMeQuery, TenantFromPrebuildFragment } from '@/generated/graphql'

export const ANALYTICS_EVENTS = {
  PAGE_VIEW: 'Page View',
  BUTTON_CLICK: 'Button Click',
  FORM_SUBMIT: 'Form Submit',
  USER_SIGNED_IN: 'User Signed In',
  USER_SIGNED_UP: 'User Signed Up',
  USER_SIGNED_OUT: 'User Signed Out',
}

const MIXPANEL_TOKEN = config.MIXPANEL_TOKEN

interface CommonEventProperties {
  timestamp?: number
  path?: string
  referrer?: string
}

type InitProps = {
  tenant: TenantFromPrebuildFragment
}

export const MixpanelAnalytics = {
  init: ({ tenant }: InitProps) => {
    try {
      if (MIXPANEL_TOKEN) {
        mixpanel.init(MIXPANEL_TOKEN, {
          debug: config.FEEDR_ENVIRONMENT !== 'prod',
          track_pageview: true,
          persistence: 'localStorage',
          ignore_dnt: true,
          record_mask_text_selector: '[type="password"]',
          record_mask_text_class: '[type="password"]',
          record_block_selector: '',
          record_collect_fonts: true,
        })
        mixpanel.register({
          tenantName: tenant.name,
          tenantId: tenant.id,
        })
      }
    } catch (error) {
      console.error('Error initializing Mixpanel:', error)
    }
  },

  track: async <T extends Record<string, any>>(
    eventName: string,
    properties?: T & CommonEventProperties,
  ) => {
    return new Promise((resolve, reject) => {
      try {
        if (MIXPANEL_TOKEN) {
          mixpanel.track(
            eventName,
            {
              ...properties,
              timestamp: Date.now(),
              path: typeof window !== 'undefined' ? window.location.pathname : undefined,
            },
            resolve,
          )
        }
        resolve(null)
      } catch (error) {
        console.error('Error tracking event:', error)
        reject(error)
      }
    })
  },

  pageView: (pageName: string, properties?: Record<string, any>) => {
    try {
      if (MIXPANEL_TOKEN) {
        void MixpanelAnalytics.track(ANALYTICS_EVENTS.PAGE_VIEW, {
          page: pageName,
          ...properties,
        })
      }
    } catch (error) {
      console.error('Error tracking page view:', error)
    }
  },

  setPeople: (properties: Record<string, any>) => {
    try {
      if (MIXPANEL_TOKEN) {
        mixpanel.people.set(properties)
      }
    } catch (error) {
      console.error('Error setting people properties:', error)
    }
  },

  login: (user?: GetMeQuery['me']) => {
    try {
      if (MIXPANEL_TOKEN && user) {
        mixpanel.identify(user.id)
        MixpanelAnalytics.setPeople({
          $id: user.id,
          $name: user.fullName,
          $email: user.email,
          ...(user.account && { accountId: user.account.id, accountName: user.account.name }),
          ...(user.accountLocation && { accountLocationId: user.accountLocation.id }),
          ...((user.invoiceApproved || user.role === 'MANAGER') && {
            invoiceApproved: user.role === 'MANAGER' || user.invoiceApproved,
          }),
          ...(user.isAdmin && { isAdmin: user.isAdmin }),
          ...(user.isUser && { isUser: user.isUser }),
          ...(user.isVendor && { isVendor: user.isVendor }),
          ...(user.languageSettings && { locale: user.languageSettings.locale }),
          ...(user.legacyAdminId && { legacyAdminId: user.legacyAdminId }),
          ...(user.role && { role: user.role }),
          ...(user.teamId && { teamId: user.teamId }),
          ...(user.vendor && { vendorName: user.vendor.companyName, vendorId: user.vendor.id }),
        })
        void MixpanelAnalytics.track(ANALYTICS_EVENTS.USER_SIGNED_IN)
      }
    } catch (error) {
      console.error('Error logging in:', error)
    }
  },

  logout: () => {
    try {
      if (MIXPANEL_TOKEN) {
        void MixpanelAnalytics.track(ANALYTICS_EVENTS.USER_SIGNED_OUT)
        mixpanel.reset()
      }
    } catch (error) {
      console.error('Error logging out:', error)
    }
  },

  recordScreen: () => {
    try {
      if (MIXPANEL_TOKEN) mixpanel.start_session_recording()
    } catch (error) {
      console.error('Error recording screen:', error)
    }
  },
}
